<template>
    <div v-if="isLoggedIn" class="container page">


        <template v-if="displayPart=='choose_award'"><!-- -1: choose_award  0: nom_info  2: new_nom -->
            <div class="card">
                <div class="card-body">
                    <h1>Choose Awards Category</h1>
                    <div class="mb-5 row">
                        <div class="col-sm-2 text-center" v-if="memberClusterCode!='stm'">
                            <!--Business Awards-->
                            <a href="#" @click.prevent="displayPart='nom_info'" class="hvrlinkbutton"><sitecode-image src="butAwardBusiness.jpg" class="img-fluid" /></a>
                        </div>
                        <div class="col-sm-2 text-center hvrContainer" v-if="isSantamHumanCapital">
                            <!--Human Capital Awards-->
                            <a href="#" @click.prevent="$router.push({ name: 'SantamHumanCapitalAward' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardSantamHumanCapital.jpg" class="img-fluid" />
                            </a>
                        </div>
                        <div class="col-sm-2 text-center hvrContainer" v-if="isHumanCapital">
                            <!--Human Capital Awards-->
                            <a href="#" @click.prevent="$router.push({ name: 'HumanCapitalAward' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardHumanCapital.jpg" class="img-fluid" />
                            </a>
                        </div>
                        <div class="col-sm-2 text-center hvrContainer" v-if="isSBSAward">
                            <!--SBS Awards-->
                            <a href="#" @click.prevent="$router.push({ name: 'SBSAward' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardSantamBrokerSolutions.jpg" class="img-fluid" />
                            </a>
                        </div>
                        <div class="col-sm-2 text-center hvrContainer" v-if="isSSSAward">
                            <!--SBS Awards-->
                            <a href="#" @click.prevent="$router.push({ name: 'SSSAward' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardSantamSpecialisedSolutions.jpg" class="img-fluid" />
                            </a>
                        </div>
                        <div v-if="memberClusterCode=='stm' && santamCEOAwardStatus=='nominating'" class="col-sm-2 text-center hvrContainer">
                            <!-- Santam CEO Award-->
                            <a href="#" @click.prevent="$router.push({ name: 'CEOaward' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardSantamCEO.jpg" class="img-fluid"  />
                            </a>
                        </div>
                        <div v-if="memberClusterCode=='stm' && santamCEOAwardStatus=='voting'" class="col-sm-2 text-center hvrContainer">
                            <!-- Santam CEO Award Voting-->
                            <a href="#" @click.prevent="$router.push({ name: 'CEOawardVote' })" class="hvrlinkbutton">
                                <sitecode-image src="butAwardSantamCEOVote.jpg" class="img-fluid" />
                            </a>
                        </div>
                        <div v-if="memberClusterCode=='stm' && santamCEOAwardStatus=='closed' && !isSantamHumanCapital && !isHumanCapital && !isSBSAward && !isSSSAward" class="col text-center hvrContainer">
                            <!-- Santam CEO Award Voting-->
                            <div class="my-5">&nbsp;</div>
                            <h3 class="my-5">All Santam Awards are currently closed.</h3>
                            {{santamCEOAwardStatus}} {{isHumanCapital}} {{isSBSAward}} {{isSSSAward}}
                        </div>


                    </div>
                </div>
            </div>
        </template>

        <template v-if="displayPart=='nom_info' && nomCatList.length !== 0">


            <div class="nomMessage">
                <div class="">

                    <b-card no-body>
                        <b-tabs pills vertical lazy card no-nav-style nav-wrapper-class="col-12 col-md-4" content-class="col-12 col-md-8">

                            <!-- ************ SRA ************-->
                            <template v-if="memberClusterCode==='sra'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosra.jpg')" alt=""></b-img></div>
                                        <div class="mb-3 text-center ceo">Anton Gildenhuys</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>SA Retail Affluent Awards - CEO Message</h3>
                                        <hr />
                                        <p>In SA Retail Affluent we know people are the differentiating factor in our business. Whether it is through providing a great client experience, implementing a creative new innovation or continuously lifting the bar of operational excellence, good things happen because of our passionate, committed and talented people.</p>
                                        <p>The SA Retail Affluent Awards programme has been designed to reward and recognise those who go beyond the status quo to make the difference. Our winners make a positive impact not only on their own clients and stakeholders, but they also motivate everyone around them to do the same.</p>
                                        <p>Recognising our top achievers is one of the most rewarding things I do as CEO of SA Retail Affluent.  I encourage you to participate in this flagship programme.</p>
                                        <p>Kind regards</p>
                                        <p>Anton Gildenhuys</p>
                                        <p>CEO: SA Retail Affluent</p>
                                    </b-card-text>
                                </b-tab>

                            </template>




                            <!-- ************ SRM / SAF / SKY ************-->
                            <template v-else-if="memberClusterCode==='srm' || memberClusterCode==='saf' || memberClusterCode==='sky'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosrm.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Vicky Reddy</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>SA Retail Mass Awards - CEO Message</h3>
                                        <hr />
                                        <p>In SA Retail Mass we know people are the differentiating factor in our business. Whether it is through providing a great client experience, implementing a creative new innovation or continuously lifting the bar of operational excellence, good things happen because of our passionate, committed and talented people.</p>
                                        <p>The SA Retail Mass Awards programme has been designed to reward and recognise those who go beyond the status quo to make the difference. Our winners make a positive impact not only on their own clients and stakeholders, but they also motivate everyone around them to do the same.</p>
                                        <p>Recognising our top achievers is one of the most rewarding things I do as CEO of SA Retail Mass.  I encourage you to participate in this flagship programme.</p>
                                        <p>Kind regards</p>
                                        <p>Vicky Reddy</p>
                                        <p>CEO: SA Retail Mass</p>
                                    </b-card-text>
                                </b-tab>
                            </template>

                            <!-- ************ SIG ************-->
                            <template v-else-if="memberClusterCode==='sig'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosig.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Carl Roothman</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>Sanlam Investment Group - CEO Message</h3>
                                        <hr />
                                        <p>Welcome to the online Sanlam Investment Group Recognition platform!</p>
                                        <p>We recognise that our employees are unique. It is our employees determination and dedication which allows us to meet our clients' needs and generate long term value for our shareholders.</p>
                                        <p>An important part of our Culture is to recognise where employees have made a significant contribution to our business objectives. Whether it is through Collaboration, Care & Innovation or Leadership. By building a culture of recognition, we reward behaviour that allows us to continue delivering excellent results.</p>
                                        <p>The Sanlam Investment Group Recognition awards provide you with an opportunity to nominate a colleague or team for achieving great results and displaying our company values.</p>
                                        <p>Thank you for your participation.</p>
                                        <p>
                                            "Everyone wants to be appreciated, so if you appreciate someone, don't keep it a secret."<br />
                                            – Mary Kay Ash
                                        </p>

                                        <p>Regards</p>
                                        <p>Carl Roothman</p>
                                        <p>CEO: Sanlam Investment Group</p>
                                    </b-card-text>
                                </b-tab>
                            </template>

                            <!-- ************ SF ************-->
                            <template v-else-if="memberClusterCode==='sf'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceogo.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Paul Hanratty</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>Sanlam Fintech - CEO Message</h3>
                                        <hr />
                                        <p>Sanlam, as a world class organisation and an employer of choice, believes in the importance of recognising our employees for their support of our values and our strategic objectives and for their commitment to excellence in every aspect of their work.</p>
                                        <p>The Sanlam Group Office is critically positioned to support the strategic objectives of our organisation and the excellence expected of Group Office employees undoubtedly impact our success and our bottom line. The Group Office recognition programme is specifically focused on acknowledging superior performance, innovation and leadership. In this, it is aimed at inspiring and encouraging employees to distinguish themselves – not least as people to be emulated and admired.</p>
                                        <p>I encourage every Group Office employee to enthusiastically support the programme and, ultimately, the strategic objectives of the Sanlam Group itself.</p>
                                        <p>Paul Hanratty</p>
                                        <p>Group Chief Executive</p>
                                    </b-card-text>
                                </b-tab>

                            </template>

                            <!-- ************ GO ************-->
                            <template v-else-if="memberClusterCode==='go' || memberClusterCode==='lsm'">
                                <!--<b-tab>
            <template v-slot:title>
                <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/humancapitalJM.jpg')" alt=""></b-img></div>
                <div class="mb-1 text-center ceo">Jeanett Modise</div>
            </template>
            <b-card-text class="text-left">
                <h3>Human Capital Awards</h3>
                <hr />

                <p>As human capital colleagues we guide and encourage others to give recognition, it is essential that we do the same for our human capital colleagues and to thank them for jobs well done.</p>
                <p>The Group Human Capital recognition programme is specifically focused on acknowledging those who:</p>
                <ul>
                    <li>provide simple and innovative solutions;</li>
                    <li>exceptional service delivery to clients; and</li>
                    <li>client centricity.</li>
                </ul>
                <p>Recognition inspires and encourages employees and teams to distinguish themselves and to inspire others. And the behaviour of saying thank you takes very little effort but means so much to those being recognised.</p>
                <p>I encourage every human capital colleague to enthusiastically support this programme and, ultimately, the strategic objectives our Group.</p>
                <p>Kind regards,</p>
                <p>Jeanett Modise</p>
            </b-card-text>
        </b-tab>-->

                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceogo.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Paul Hanratty</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>Group Office - CEO Message</h3>
                                        <hr />
                                        <p>Sanlam, as a world class organisation and an employer of choice, believes in the importance of recognising our employees for their support of our values and our strategic objectives and for their commitment to excellence in every aspect of their work.</p>
                                        <p>The Sanlam Group Office is critically positioned to support the strategic objectives of our organisation and the excellence expected of Group Office employees undoubtedly impact our success and our bottom line. The Group Office recognition programme is specifically focused on acknowledging superior performance, innovation and leadership. In this, it is aimed at inspiring and encouraging employees to distinguish themselves – not least as people to be emulated and admired.</p>
                                        <p>I encourage every Group Office employee to enthusiastically support the programme and, ultimately, the strategic objectives of the Sanlam Group itself.</p>
                                        <p>Paul Hanratty</p>
                                        <p>Group Chief Executive</p>
                                    </b-card-text>
                                </b-tab>
                            </template>

                            <!-- ************ SC ************-->
                            <template v-else-if="memberClusterCode==='sc'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosc.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Kanyisa Mkhize</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>Sanlam Corporate - Introduction</h3>
                                        <hr />
                                        <p>The Sanlam Corporate <b>UDUMO* Awards</b> are designed to recognise and celebrate employees who exemplify our Sanlam Values, deliver Outstanding Performance, Champion Client Centricity and act as Catalysts in their Communities. Awards can be given to individuals or teams who embody these qualities.</p>
                                        <p><small><strong>* "Udumo" is the Zulu word meaning praise, honour, prestige, celebrity and fame.</strong></small></p>
                                        <hr />
                                        <h3>Message from Kanyisa Mkhize</h3>
                                        <p>We encourage all our colleagues to recognize and celebrate outstanding work. Whether it’s someone going the extra mile, delivering excellent client service both internally or externally, or fully living our Sanlam Values. Let’s acknowledge their efforts and inspire excellence across our organization.</p>
                                        <p class="font-italic">As we reflect on our achievements this year, we encourage everyone to take a moment to recognise the incredible contributions of our colleagues.</p>
                                        <p class="font-italic">Think about those who have gone above and beyond, demonstrated exceptional teamwork, or inspired you with their hard work, and make that nomination! Your nominations can highlight their dedication and make a meaningful impact.</p>
                                        <p class="font-italic">Please take a few minutes to submit your nominations.  Let’s show our appreciation for the amazing people we work with!</p>
                                        <p class="font-italic">Thank you for helping us celebrate our team’s successes!</p>
                                    </b-card-text>
                                </b-tab>
                            </template>

                            <!-- ************ STM ************-->
                            <template v-else-if="memberClusterCode==='stm'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceostm.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">&nbsp;</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>CEO Message</h3>
                                        <hr />

                                        <!--
                <p>Dear Colleagues</p>
                <h4><b>CEO Award Vote</b></h4>


                <p>I am pleased to announce that we received an overwhelming amount of nominations in all five categories and I am very happy that you have taken the time to acknowledge and recognise your peers in such an enthusiastic way.&nbsp; Our executive team deliberated, and shortlisted two finalists each for the Everyday Hero and Living Purposefully awards.</p>
                <ul>
                    <li><strong><em>The Everyday Hero</em></strong> award recognises an employee who may not be in a senior or formal leadership position, but who leads from within.&nbsp; This employee sees opportunities where others may see obstacles. Spotting opportunity and inspiring others to work with you to turn opportunity into growth is worthy of being recognized and celebrated</li>
                    <li><strong><em>Living Purposefully</em></strong> award celebrates an individual whose contribution has had a significant and positive impact on their community and society. Their state of social conscience and selflessness is worthy of being recognized.</li>
                </ul>
                <p>As communicated previously, you now have the opportunity to vote for the winners of both these categories on the Santam Applause recognition platform.</p>
                <p><strong>Voting process</strong></p>
                <ul>
                    <li>After you have voted for one of the two finalists under the Every Day Hero Award category you will automatically be taken to the Living purposeful award page, where you will again be able to vote for one of the two finalists.</li>
                    <li>Voting will close at <strong><em>5pm on 19&nbsp;November 2021</em></strong></li>
                </ul>
                <p>The award event will be held virtually on the 2<sup>nd</sup> of December and all employees will be invited to a virtual event to recognise and celebrate the finalists and winners’ achievements.</p>
                <p>Details of the event will be shared during November.</p>


                <p>&nbsp;</p>
                    -->


                                        <p>Dear Colleagues</p>
                                        <p>Nominations are now open for the Santam Group CEO Awards. The awards have been created as the top recognition platform across the Santam Group.</p>
                                        <p>These awards afford you the opportunity to identify excellence and to recognise your most outstanding colleagues – those individuals and teams who have excelled, either through sustained performance over time and/or worked on a project which impacted on the Group's ability to achieve the organisational objectives in the last financial year (January to December) while living <i>The Santam Way values</i>.</p>


                                        <!--
                <p>Dear Colleagues</p>
                <p>Nominations are now open for the Santam Group CEO Awards! This is your opportunity to celebrate those who have in the face of ambiguity and adversity continued to deliver Insurance Good and Proper with passion, humanity, integrity, excellence, innovative thinking, and tenacity.</p>
                <p>You will be able to nominate your peers within five categories, two of which could also be team categories. Our executive team will then decide on the finalists for all the categories. However, for both the Everyday Hero Award and the Living Purposefully Award you will have an opportunity to vote for the winners.</p>
                <p>Nominations close on <b>Monday, 27 September 2021</b> so take advantage of this opportunity to acknowledge, recognise and celebrate worthy individuals or teams and help us shape an experience where our most exceptional people feel recognised and are rewarded.</p>
                <p>I look forward to us recognising and celebrating your exceptional performance.</p>
                    -->
                                        <!--
                <p>Nominations are now open for the Santam Group CEO Awards. The awards have been created as the top recognition platform across the Santam Group.</p>
                <p>I am pleased to announce that we have reviewed our CEO Awards process and categories in order to drive increased acknowledgment, praise and recognition – but more importantly - for us to celebrate those who have in the face ambiguity and adversity continued to deliver Insurance Good and Proper with passion, humanity, integrity, excellence, innovative thinking, and tenacity.</p>
                <p>This year, employees will be able to nominate their peers within 5 categories, 2 of which could also be team categories. Employees will also be provided with an opportunity to vote for a winner within 2 categories as well. </p>
                -->
                                        <!--
                <p>These awards afford you the opportunity to identify excellence and to recognise your most outstanding colleagues – those individuals and teams who have excelled, either through sustained performance over time and/or worked on a project which impacted on the Group's ability to achieve the organisational objectives in the last financial year (January to December) while living <i>The Santam Way values</i>.</p>
                -->

                                        <p class="mt-3">CEO: Santam</p>
                                        <p>Lize Lambrechts</p>
                                    </b-card-text>
                                </b-tab>
                            </template>

                            <!-- ************ SEM ************-->
                            <template v-else-if="memberClusterCode==='sem'">
                                <b-tab>
                                    <template v-slot:title>
                                        <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosem.jpg')" alt=""></b-img></div>
                                        <div class="mb-1 text-center ceo">Heinie Werth</div>
                                    </template>
                                    <b-card-text class="text-left">
                                        <h3>Sanlam Emerging Markets - CEO</h3>
                                        <hr />
                                        <p>Dear colleagues</p>
                                        <p>Our staff are Sanlam Emerging Markets' greatest asset. To reward your valuable contribution to the cluster, we're delighted to launch the 2020 SEM Collaboration Award to you.</p>
                                        <p>It's important for us to recognise you for supporting our strategic objectives, and with a primary focus on collaboration we can achieve even greater results. With this in mind, we've created the Collaboration Award to celebrate your collaborative efforts, whether as an individual or in teams, within the SEM Group Office.</p>
                                        <p>Your skills and contributions play an important role in SEM's achievements. Therefore, I want to encourage you to keep collaboration top of mind as you contribute to the success of our business so that we can have the opportunity to reward your performance.</p>

                                        <p>Warm regards</p>
                                        <p>Heinie Werth</p>

                                    </b-card-text>
                                </b-tab>
                            </template>


                            <!-- ************ ALL OTHERS ************-->
                            <template v-else>
                                <b-tab title="Unknown Cluster">
                                    <b-card-text class="text-left">
                                        <h3>Unknown Cluster Info: {{memberClusterCode}}</h3>
                                    </b-card-text>
                                </b-tab>
                            </template>
                        </b-tabs>
                    </b-card>


                    <div class="text-right mt-3 mb-5">

                        <template v-if="memberClusterCode=='stm'">
                            <!-- Santam Specific -->
                            <!--<router-link to="/awardnominations" class="mr-3 btn btn-secondary">View Nominations</router-link>-->
                            <b-button type="button" variant="primary" class="mr-3" v-on:click="nomCatInit();displayPart='new_nom'">New Nomination</b-button>

                            <!--<template v-if="false">
            <router-link to="/ceoawardvote" class="mr-3 btn btn-primary">CEO Award Vote</router-link>
        </template>
        <template v-else>
            <router-link to="/ceoaward" class="mr-3 btn btn-primary">CEO Award</router-link>
        </template>-->
                        </template>

                        <template v-else-if="memberClusterCode=='sig'">
                            <!-- SIG specific -->
                            <!--SIG Bronwyn Jacobs requested View Nominations button to be removed - 30/05/2022
        <router-link to="/awardnominations" class="mr-3 btn btn-secondary">View Nominations</router-link>-->
                            <!--<router-link to="/awardnominations" class="mr-3 btn btn-secondary">View Nominations</router-link>-->

                            <div v-if="true" class="alert alert-secondary" role="alert">Nominations are Now Closed</div>
                            <template v-else>
                                <b-button type="button" variant="primary" v-on:click="nomCatInit();displayPart='new_nom'">New Nomination</b-button>
                            </template>
                        </template>

                        <template v-else-if="memberClusterCode=='saf' || memberClusterCode=='sky' || memberClusterCode=='srm'">
                            <div v-if="false" class="alert alert-secondary" role="alert">Nominations are Now Closed</div>
                            <template v-else>
                                <b-button type="button" variant="primary" v-on:click="nomCatInit();displayPart='new_nom'">New Nomination</b-button>
                            </template>
                        </template>

                        <template v-else-if="memberClusterCode=='sc'">
                            <div v-if="true" class="alert alert-secondary" role="alert">Nominations are Now Closed</div>
                            <template v-else>
                                <b-button type="button" variant="primary" v-on:click="nomCatInit();displayPart='new_nom'">New Nomination</b-button>
                            </template>
                        </template>



                        <template v-else>
                            <router-link v-if="memberClusterCode=='sra'" to="/awardnominations" class="mr-3 btn btn-secondary">View Nominations</router-link>
                            <!--<router-link to="/awardnominations" class="mr-3 btn btn-secondary">View Nominations</router-link>-->
                            <b-button type="button" variant="primary" v-on:click="nomCatInit();displayPart='new_nom'">New Nomination</b-button>

                        </template>
                    </div>
                </div>

                <div class="text-center mt-3">
                    <h5>HALL OF FAME</h5>

                    <template v-if="hallOfFameData.length===0">
                        <small><i>No winners have been concluded</i></small>
                        <!--<div class="row">
                            <div class="col-6 mb-3">
                                <sitecode-image src="nopic.jpg" class="img-fluid" /><br />-->
                                <!--John Smith-->
                            <!--</div>
                           
                        </div>-->
                    </template>
                    <template v-else>
                        <div class="row">
                            <div v-for="n in hallOfFameData" :key="n._rowNum" class="col-sm-3 p-3">
                                <div class="border rounded p-2">
                                    <div class="text-center"><img class="img-fluid" :src="('/profilepic/' + n.picId)" /></div>
                                    <div class="text-center"><b>{{n.memberName}}</b></div>
                                    <div class="text-center"><small>{{n.division}}</small></div>
                                    <div class="text-center"><b><i>{{n.nomcat}}</i></b></div>
                                    <div class="text-center"><i>{{n.dateConcluded.sjToDate('MMMM yyyy')}}</i></div>
                                </div>
                            </div>

                        </div>
                    </template>



                </div>
            </div>




        </template>
        <!--
    <template v-if="displayPart==1">
        <div class="text-right"><a href="#" v-on:click.prevent="formReset">Reset</a></div>
        <member-select title="Choose who to nominate"
                       selectedTitle="Selected nominees"
                       :defaultCountryCode="memberClusterCode"
                       :defaultDivisionCode="memberDivisionCode"
                       isCountryFixed
                       v-bind:selectedMems="selectedNominees"
                       v-on:continue="nomineesSelected"
                       v-on:cancel="nomineesSelectedCancel" />
    </template>
    -->
        <template v-if="displayPart=='new_nom'">
            <div class="row mb-2">

                <div class="col-sm-8">
                    <h3>Award Nomination</h3>
                    Select an Award below, then complete your nomination.
                </div>
                <div class="col-sm-4 text-right">
                    <a href="#" @click.prevent="displayPart='nom_info'" class="mr-1 btn btn-secondary">Back</a> <a href="#" @click.prevent="formReset" class="mr-1 btn btn-secondary">Clear Page Entries</a>
                </div>
            </div>

            <section class="mb-3 card card-default" v-if="$store.state.memberStore.member.clusterCode == 'lsm'">
                <div class="card-body">
                    <div>Cluster: <a href="#" @click.prevent="showChangeCluster()" class="mr-1"><b>{{ this.$store.state.countryList.filter(country => country.countryCode === memberClusterCode)[0].country }}</b></a><button @click="showChangeCluster()" class="ml-5 btn btn-sm btn-primary">Change</button></div>
                </div>
            </section>

            <section class="card mb-4 nomAwardCategory3">
                <!--<b-tabs v-model="tabIndex" pills vertical lazy card no-nav-style nav-class="selcatNav" nav-wrapper-class="col-12 col-md-4" content-class="col-12 col-md-8">-->
                <b-tabs v-model="tabIndex" lazy card nav-class="selcatNav">

                    <!-- ************ SRA ************-->
                    <template v-if="memberClusterCode==='sra'">

                        <b-tab title="SRA CEO Award" @click="nomCatSelected('SRA CEO Award')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="spfCEOAward.jpg" class="w-100" /></div>
                                <h3>SA Retail Affluent CEO Award </h3>
                                <hr />
                                <p>In Retail Affluent we know people are the differentiating factor in our business. Whether it is through providing a great client experience, implementing a creative new innovation or continuously lifting the bar of operational excellence, good things happen because of our passionate, committed and talented people.</p>
                                <p>The <strong>RETAIL AFFLUENT CEO AWARD</strong> is the highest recognition, It is awarded every year to employees who, by being determined and resolute, really stood out in the contributions that they made to our business. These contributions can be recognised in any of the following:</p>
                                <ul>
                                    <li>Growing shareholder value</li>
                                    <li>Client Experience and centricity</li>
                                    <li>Product Leadership and Channel Development</li>
                                    <li>Operational Excellence</li>
                                    <li>Living the Sanlam values; and</li>
                                    <li>Collaboration with other stakeholders</li>
                                </ul>
                                <p>The SA Retail Affluent Awards programme has been designed to reward and recognise those who go beyond the status quo to make the difference. Our nominees make a positive impact not only on their own clients and stakeholders, but they also motivate everyone around them to do the same.</p>
                                <p>Recognising our top achievers is one of the most rewarding things I do as CEO of SA Retail Affluent. I encourage you to participate in this flagship programme</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Client Champion Award" @click="nomCatSelected('Client Champion Award')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="spfClientChampionAward.jpg" class="w-100" /></div>
                                <h3>Client Champion Award</h3>
                                <hr />
                                <p>A culture of caring is central to being a Wealthsmith&trade; – not least, caring for our clients. After all, they are the reason for our existence and everything we do is aimed at helping them live their best lives. This requires all of us to be committed champions, working tirelessly to ensure that our clients experience the very best that our brand has to offer.</p>
                                <p>The Client Champion Award aims to celebrate and recognise your contribution to how clients experience our brand and everything we stand for.</p>
                                <div>It focusses on:</div>
                                <ul>
                                    <li>Serving with pride.</li>
                                    <li>Caring because we respect others.</li>
                                </ul>
                                <p>Any permanent employee, irrespective of job grade, has an equal chance of winning this award.</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Influencer Award" @click="nomCatSelected('Influencer Award')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="spfInfluencerAward.jpg" class="w-100" /></div>
                                <h3>Influencer Award</h3>
                                <hr />
                                <p>A Wealthsmith&trade; is a can-do optimist, always looking for what can be done (instead of focusing on what can't be done), displaying leadership not just in their own work, but also when leading teams.</p>
                                <p>In the Influencer Award we aim to recognise employees who lead with courage and take responsibility for getting things done, in other words collaborating and creating a culture of consistency and flexibility, as well as competition and collaboration.  Individual leadership and organisational leadership is the name of the game, and this award is reserved for the Wealthsmiths&trade; who act with integrity, accountability and vision, and who inspires others and drive results.</p>
                                <div>Ultimately, this award should;</div>
                                <ul>
                                    <li>Motivate all SA Retail Affluent employees to Lead with Courage and to be CEO's of their own careers;</li>
                                    <li>Ensure long-term sustainability and growth of our organisation;</li>
                                    <li>Develop a common mind-set among employees;</li>
                                    <li>Enhance organisational flexibility; and</li>
                                    <li>Influence the environment within which they work positively. </li>
                                </ul>
                                <p>This award is not limited to line management. Any permanent employee, irrespective of job grade, has an equal chance of winning.</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Innovator Award" @click="nomCatSelected('Innovator Award')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="spfInnovationAward.jpg" class="w-100" /></div>
                                <h3>Innovator Award</h3>
                                <hr />
                                <p>At the heart of being a Wealthsmith&trade; is a culture of innovation.  This requires all of us to think creatively, suggest innovative business ideas, apply design-thinking principles and, when needed, find work-arounds for our existing business processes.</p>
                                <p>The Innovator Award encourages and gives recognition to employees for innovative business ideas that support SA Retail Affluent's strategic objectives. It also creates an internal channel to establish a tangible opportunity for employees to be true Wealthsmiths&trade;.</p>
                                <div>The Innovator Award is designed around the following core values:  </div>
                                <ul>
                                    <li>Grow shareholder value through innovation;</li>
                                    <li>Serving with pride; and</li>
                                    <li>Leading with courage</li>
                                </ul>
                                <p>To achieve our strategic objective employees are encouraged to adopt an entrepreneurial spirit and to establish a culture of innovation in their day-to-day activities. In doing so, we will be able to become a truly client-centric company. </p>
                            </b-card-text>
                        </b-tab>
                    </template>


                    <!-- ************ SRM / SAF / SKY ************-->
                    <template v-else-if="memberClusterCode==='srm' || memberClusterCode==='saf' || memberClusterCode==='sky'">
                        <!--<b-tab title="Retail Mass CEO Award" @click="nomCatSelected('RM CEO Award')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="spfCEOAward.jpg" class="w-100" /></div>
                                <h3>Retail Mass CEO Award</h3>
                                <hr />
                                <p>The RM CEO Award will be awarded to individuals or teams who have provided an outstanding contribution through their role and added value to the business or customers through their achievements.</p>
                                <p>Behaviours that this award will encourage and recognize staff for displaying are:</p>
                                <ul>
                                    <li>Innovation ideas which have significant positive impact.</li>
                                    <li>Significantly increase client-base and sales.</li>
                                    <li>Contribution to significant improvements in brand awareness and presence.</li>
                                    <li>Actions which result industry awards/recognition.</li>
                                    <li>Noticeable Value Added to business.</li>
                                    <li>Actions which result in significant improvement in business efficiency and effectiveness.</li>

                                </ul>
                            </b-card-text>
                        </b-tab>-->
                        <b-tab title="CARE" @click="nomCatSelected('Care for all')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="nomAllStarsBanner.jpg" class="w-100" /></div>
                                <h3>Care for all</h3>
                                <hr />
                                <p>Serving with empathy and consideration, knowing that everything you do leaves a lasting impact and legacy</p>
                                <p><b>Employee Experience:</b> Growing.</p>
                                <p>“I feel valued and cared for”</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="COLLABORATION" @click="nomCatSelected('Collaborating')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="nomAllStarsBanner.jpg" class="w-100" /></div>
                                <h3>Collaborating</h3>
                                <hr />
                                <p>Commits to unlocking our #WinningAsOne spirit by focusing on a better outcome for all, achieved through partnership</p>
                                <p>and an open-minded approach to everything</p>
                                <p><b>Employee Experience:</b> Connecting.</p>
                                <p>“I feel trusted and understood”</p>

                            </b-card-text>
                        </b-tab>
                        <b-tab title="INNOVATION" @click="nomCatSelected('Leading through Innovation')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="nomAllStarsBanner.jpg" class="w-100" /></div>
                                <h3>Leading through Innovation</h3>
                                <hr />
                                <p>Always striving for continuous improvement in order to create value for our stakeholders, our society and our world</p>
                                <p><b>Employee Experience:</b> Leading.</p>
                                <p>“I feel invested in this”</p>

                            </b-card-text>
                        </b-tab>
                        <b-tab title="INTEGRITY" @click="nomCatSelected('Integrity in everything you do')">
                            <b-card-text class="text-left">
                                <div class="mb-3"><sitecode-image src="nomAllStarsBanner.jpg" class="w-100" /></div>
                                <h3>Integrity in everything you do</h3>
                                <hr />

                                <p>Unwavering in pursuit of doing the right thing. Resolute in commitment to what is good for all our stakeholders
                                <p><b>Employee Experience:</b> Trusting.</p>
                                <p>“I trust Sanlam’s promise to me”</p>

                            </b-card-text>
                        </b-tab>
                    </template>

                    <!-- ************ SIG ************-->
                    <template v-else-if="memberClusterCode==='sig'">
                        <b-tab title="Care" @click="nomCatSelected('Care')">
                            <b-card-text class="text-left">
                                <h3>Care</h3>
                                <hr />
                                <p>Serving with empathy and consideration, knowing that everything we do leaves a lasting impact and legacy.</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Innovation" @click="nomCatSelected('Innovation')">
                            <b-card-text class="text-left">
                                <h3>Innovation</h3>
                                <hr />
                                <p>Always striving for continuous improvement in order to create value for our stakeholders, our society and our world.</p>
                            </b-card-text>
                        </b-tab>
                        <!--<b-tab title="Integrity" @click="nomCatSelected('Integrity')">
                            <b-card-text class="text-left">
                                <h3>Integrity</h3>
                                <hr />
                                <p>Unwavering in our pursuit to do the right thing; resolute in our commitment to what’s good for all stakeholders.</p>
                            </b-card-text>
                        </b-tab>-->
                        <b-tab title="Collaboration" @click="nomCatSelected('Collaboration')">
                            <b-card-text class="text-left">
                                <h3>Collaboration</h3>
                                <hr />
                                <p>Unlocking performance by focusing on a better outcome for all achieved through partnership and an open-minded approach.</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Human Centred Leadership" @click="nomCatSelected('Human Centred Leadership')">
                            <b-card-text class="text-left">
                                <h3>Human Centred Leadership</h3>
                                <hr />
                                <p>Demonstrates all the values and makes a significant contribution to business. Gets results through people, growing people while showing compassion and care.</p>
                            </b-card-text>
                        </b-tab>
                        <!--<b-tab title="Collaboration" @click="nomCatSelected('Collaboration')">
        <b-card-text class="text-left">
            <h3>Collaboration</h3>
            <hr />
            <p>The Collaboration award recognises teams who collaborate across teams / business in order to provide customer centric service, solutions or products. Such collaboration is done in a spirit of teamwork and respectful treatment of others. These teams consistently show a ONE Sanlam Investment Group approach to specific initiatives and/or customer successes. Their approach is focused on creating a positive experience for the customer through collaboration.</p>
        </b-card-text>
    </b-tab>
    <b-tab title="Creativity &amp; Innovation" @click="nomCatSelected('Creativity & Innovation')">
        <b-card-text class="text-left">
            <h3>Creativity &amp; Innovation</h3>
            <hr />
            <p>The Creativity & Innovation award recognises employees who deliver innovation resulting in strategic and operational efficiency and effectiveness, ultimately creating  sustainable business value for internal and/or external customers while living the Sanlam Investment Group values.</p>
        </b-card-text>
    </b-tab>
    <b-tab title="Distinguished Leadership" @click="nomCatSelected('Distinguished Leadership')">
        <b-card-text class="text-left">
            <h3>Distinguished Leadership</h3>
            <hr />
            <p>The Distinguished Leadership award recognises leaders with or without titles, who best demonstrate and drive the Sanlam Investment Group values and leadership capabilities. They drive performance, diversity & inclusion, contribute to the development of others through knowledge/information sharing, mentoring and/or coaching.</p>
        </b-card-text>
    </b-tab>
    <b-tab title="Client Centricity" @click="nomCatSelected('Client Centricity')">
        <b-card-text class="text-left">
            <h3>Client Centricity</h3>
            <hr />
            <p>To recognise employees that, while delivering on goals and achieving results, also build strong client relationships and deliver client centric solutions consistently. These employees keep the client at the centre of every engagement by demonstrating urgency, takes ownership of mistakes and seek for ways to improve the client experience.</p>
        </b-card-text>
    </b-tab>
    <b-tab title="Annual SI Group Award" @click="nomCatSelected('Annual SI Group Award')">
        <b-card-text class="text-left">
            <h3>Annual Sanlam Investment Group Award</h3>
            <hr />
            <p>The pinnacle recognition award across the Sanlam Investment Group. Celebrates, honours and rewards employees who through sustained high performance and/or a significant event have impacted on SIG’s ability to achieve the organisational objectives.</p>
        </b-card-text>
    </b-tab>-->
                    </template>

                    <!-- ************ GO ************-->
                    <!--<template v-else-if="memberClusterCode==='go' || memberClusterCode==='lsm'">
        <b-tab title="Client Centricity" @click="nomCatSelected('Client Centricity')">
            <b-card-text class="text-left">
                <h3>Client Centricity</h3>
                <hr />
                <p><b>Client centricity</b> – knowing our clients’ business drivers and/or our people’s unique requirements to succeed and grow are critical.  This award recognises HC colleagues who take the time to understand these and provide value add offerings to support their clients.</p>

            </b-card-text>
        </b-tab>
        <b-tab title="Simple Innovative Solutions" @click="nomCatSelected('Leadership Award')">
            <b-card-text class="text-left">
                <h3>The Leadership Award</h3>
                <hr />
                <p><b>The Leadership Award</b> is designed around the core value of <b>Lead with courage</b>. Leadership is not just about filling a formal leadership position, but also being a leader where you are, at whatever level and within whatever environment. Leadership is about taking ownership of your own behaviour and constantly developing yourself.</p>
                <p>Leadership is about adopting a growth mind-set. On an individual level, leadership is about personal mastery. On an organisational level, leadership is about having a vision, inspiring (supporting) people and driving results.</p>
                <p>The ultimate purpose of leadership in the organisation should be to ensure long-term sustainability and growth of Sanlam, to develop a common growth mind-set amongst employees, to enhance organisational flexibility and to provide structure and strategic direction.</p>
            </b-card-text>
        </b-tab>
        <b-tab title="Exceptional Service" @click="nomCatSelected('Innovation Award')">
            <b-card-text class="text-left">
                <h3>The Innovation Award</h3>
                <hr />
                <p>The <b>Innovation Award</b> is designed around our core value of <b>Grow value through innovation and superior performance</b>. The innovation award encourages and gives recognition to employees for innovative business ideas that support the Group's strategic objectives.</p>
                <p>Employees are encouraged to adopt an entrepreneurial spirit and to establish a culture of innovation in the pursuit of their day-to-day activities. In doing so we will be able to go above and beyond in meeting our objective to be a client-centric company.</p>
            </b-card-text>
        </b-tab>
    </template>-->

                    <template v-else-if="memberClusterCode==='go' || memberClusterCode==='lsm' || memberClusterCode==='sf'">
                        <b-tab title="Superior Performance Award" @click="nomCatSelected('Superior Performance Award')">
                            <b-card-text class="text-left">
                                <h3>The Superior Performance Award</h3>
                                <hr />
                                <p>The <b>Superior Performance Award</b> is designed for talented employees who willingly and unreservedly harness and invest their talents in an organized and optimally productive manner.</p>
                                <p>The Group Office would like to create a high-performance culture where exceptional <b>behaviour</b> and excellent <b>performance</b> is rewarded. This award recognizes employees who consistently perform with excellence and display behaviours that are in accordance with Sanlam's values: <b>Serve with Pride, Care because we respect others and Act with integrity and accountability</b>.</p>

                            </b-card-text>
                        </b-tab>
                        <b-tab title="Leadership Award" @click="nomCatSelected('Leadership Award')">
                            <b-card-text class="text-left">
                                <h3>The Leadership Award</h3>
                                <hr />
                                <p><b>The Leadership Award</b> is designed around the core value of <b>Lead with courage</b>. Leadership is not just about filling a formal leadership position, but also being a leader where you are, at whatever level and within whatever environment. Leadership is about taking ownership of your own behaviour and constantly developing yourself.</p>
                                <p>Leadership is about adopting a growth mind-set. On an individual level, leadership is about personal mastery. On an organisational level, leadership is about having a vision, inspiring (supporting) people and driving results.</p>
                                <p>The ultimate purpose of leadership in the organisation should be to ensure long-term sustainability and growth of Sanlam, to develop a common growth mind-set amongst employees, to enhance organisational flexibility and to provide structure and strategic direction.</p>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Innovation Award" @click="nomCatSelected('Innovation Award')">
                            <b-card-text class="text-left">
                                <h3>The Innovation Award</h3>
                                <hr />
                                <p>The <b>Innovation Award</b> is designed around our core value of <b>Grow value through innovation and superior performance</b>. The innovation award encourages and gives recognition to employees for innovative business ideas that support the Group's strategic objectives.</p>
                                <p>Employees are encouraged to adopt an entrepreneurial spirit and to establish a culture of innovation in the pursuit of their day-to-day activities. In doing so we will be able to go above and beyond in meeting our objective to be a client-centric company.</p>
                            </b-card-text>
                        </b-tab>
                    </template>

                    <!-- ************ SC ************-->
                    <template v-else-if="memberClusterCode==='sc'">
                        <b-tab title="Pillar of Trust" @click="nomCatSelected('Pillar of Trust')">
                            <b-card-text class="text-left">
                                <h3>Pillar of Trust Award (Integrity)</h3>
                                <hr />
                                <ul>
                                    <li>Initiating and championing ethical initiatives beyond job requirements.</li>
                                    <li>Demonstrates honesty and transparency in all actions and communications.</li>
                                    <li>Implementing innovative transparency measures that exceed industry standards.</li>
                                    <li>Proactively identifying and addressing potential ethical issues before they escalate and works to rectify them.</li>
                                    <li>Builds trust with colleagues, clients, and stakeholders through consistent behaviour.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Heartfelt Hero" @click="nomCatSelected('Heartfelt Hero')">
                            <b-card-text class="text-left">
                                <h3>Heartfelt Hero (Care)</h3>
                                <hr />
                                <ul>
                                    <li>Demonstrates observable acts of empathy and consideration towards all stakeholders through effective communication, responsive actions, and inclusive practices.</li>
                                    <li>Provides resources, guidance, and encouragement to help stakeholders achieve their goals and improve performance.</li>
                                    <li>Dedicated to the well-being of all stakeholders, through engaging in initiatives and programmes.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Synergy Superstar" @click="nomCatSelected('Synergy Superstar')">
                            <b-card-text class="text-left">
                                <h3>Synergy Superstar (Collaboration)</h3>
                                <hr />
                                <ul>
                                    <li>Unlocks our "Winning as One" ethos working together to implement or create a positive change within SC. </li>
                                    <li>Establishes and maintains key partnerships annually, resulting in improved collaborative project success rates.</li>
                                    <li>Champions initiatives that drive exceptional team performance and morale through sustained cooperation and respect.</li>
                                    <li>Actively contributes to the success of team projects and initiatives.</li>
                                    <li>Builds relationships across the business to achieve shared objectives that contributes to  enhancing overall company performance.</li>
                                </ul>

                            </b-card-text>
                        </b-tab>
                        <b-tab title="Trailblazer of tomorrow" @click="nomCatSelected('Trailblazer of tomorrow')">
                            <b-card-text class="text-left">
                                <h3>Trailblazer of tomorrow (Innovation)</h3>
                                <hr />
                                <ul>
                                    <li>Introduce and implement ideas that address challenges or seize opportunities, resulting in measurable improvements or efficiencies.</li>
                                    <li>Demonstrates an ability to adapt to changing circumstances, successfully implements technologies or methodologies annually, resulting in an increase in efficiency or productivity.</li>
                                    <li>Introduce and implements groundbreaking ideas and solutions that drive positive change within Sanlam Corporate.</li>
                                    <li>Leads simplification initiatives that streamline processes, enhance product offerings, and upgrade technology, contributing to growth and overall business performance.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Performance Excellence" @click="nomCatSelected('Performance Excellence')">
                            <b-card-text class="text-left">
                                <h3>Performance Excellence</h3>
                                <hr />
                                <ul>
                                    <li>Exhibits unwavering commitment to Business Reputation by consistently demonstrating an extraordinary level of pride in and constructively promotes the business positively both internally and externally.</li>
                                    <li>Surpasses client expectations consistently,  by delivering exceptional client experiences that exceed expectations and set new benchmarks for excellence.</li>
                                    <li>Implements groundbreaking inventive strategies that enhance business operations with quantifiable improvements to business operations.</li>
                                    <li>Shows a flawless and continuous history of exceeding the most difficult performance goals, producing outcomes that greatly transcend expectations.</li>
                                    <li>Achieved a minimum 4.0 PA rating.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Client Champion" @click="nomCatSelected('Client Champion')">
                            <b-card-text class="text-left">
                                <h3>Client Champion (Client centricity)</h3>
                                <hr />
                                <ul>
                                    <li>Actively integrates customer-centric principles into all business strategies and decision-making processes.</li>
                                    <li>Develops innovative processes with the customer in mind, proving an enhanced customer experience.</li>
                                    <li>Gathering and acting on customer feedback, through a structured system for collecting and analyzing feedback with clear accountability and follow-up actions.</li>
                                    <li>Utilizing data analytics to enhanced personalized service and recommend solutions and improvements tailored to support customer needs.</li>
                                    <li>Develop and implement strategic plans for nurturing long term customer relationships, including personalized engagement.</li>
                                    <li>Launch pro-active initiatives to engage the community and positively influence public perception beyond standard client practices.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Community Catalyst" @click="nomCatSelected('Community Catalyst')">
                            <b-card-text class="text-left">
                                <h3>Community Catalyst</h3>
                                <hr />
                                <ul>
                                    <li>Strives towards a more inclusive and prosperous society for all individuals through participation in programs that are created expressly to advance economic prosperity for all.</li>
                                    <li>Demonstrates quantifiable impact in seeking to uplift impoverished communities through living the Sanlam values out in the community, showing documented results and/or community endorsements.</li>
                                    <li>Active in the Employee Volunteerism Program and/or Skills-based Volunteering Program, through participation in a minimum of 3 projects in the year.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>



                        <!--<b-tab title="UDUMO Award" @click="nomCatSelected('UDUMO Award')">
                            <b-card-text class="text-left">
                                <h3>UDUMO Award</h3>
                                <hr />
                                <p>At Sanlam Corporate, we value your commitment towards Sanlam’s successes and we recognise the role that you play in creating positive experiences for our clients.  </p>
                                <p>The <b>Udumo Awards</b> shows appreciation and pays tribute to staff for all that they do. </p>
                                <p>Acknowledging our colleagues who consistently role model our values and those who have outperformed their objectives.</p>
                                <p>The award winners from our three categories are eligible for consideration for the overall annual Udumo awards and will join our successful sales team colleagues on the Sanlam Corporate incentive trip.</p>



                            </b-card-text>
                        </b-tab>-->
                    </template>

                    <!-- ************ STM ************-->
                    <!--    Santam: Commercial & Personal -->
                    <template v-else-if="memberClusterCode==='stm' && memberDivisionCode==='stm_2196'">

                        <b-tab title="Lead With Pride Award" @click="nomCatSelected('Lead With Pride')">
                            <b-card-text class="text-left">
                                <h3>Lead With Pride Award</h3>
                                <hr />
                                <p>This category recognises leadership contribution on a senior level.</p>
                                <ul>
                                    <li>Limited to JG 5/6</li>
                                    <li>Live the values</li>
                                    <li>Business results</li>
                                    <li>Achievement of the claims strategic objectives</li>
                                    <li>Leadership behaviour</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Hero Award" @click="nomCatSelected('Hero Award')">
                            <b-card-text class="text-left">
                                <h3>Hero Award</h3>
                                <hr />
                                <p>This category recognises an employee or a team that sees opportunities where others may see obstacles.</p>
                                <ul>
                                    <li>Criteria to centre around a contribution a team or individual made in overcoming a business challenge</li>
                                    <li>Spotting opportunities and inspiring others to work with you to overcome obstacles and challenges</li>
                                    <li>Act with courage to overcome challenges and obstacles in order to thrive during challenging times</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Peace of Mind Award" @click="nomCatSelected('Peace of Mind')">
                            <b-card-text class="text-left">
                                <h3>Peace of Mind Award</h3>
                                <hr />
                                <p>Celebrating an individual or a team who embodies our promise to deliver peace of mind to our clients (internal & external).</p>
                                <ul>
                                    <li>Meeting and exceeding our clients needs and expectations</li>
                                    <li>Client centricity</li>
                                    <li>Consistent delivery of our claims promise to deliver peace of mind</li>
                                    <li>Living the Santam values.</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Differentiation Award" @click="nomCatSelected('Differentiation Award')">
                            <b-card-text class="text-left">
                                <h3>Differentiation Award</h3>
                                <hr />
                                <p>This award focuses on recognising outstanding contribution and performance in specific roles. The assessors role was identified as one of the most critical roles in Claims. The following criteria will apply:  Service Excellence, Productivity, Living the Values</p>
                                <ul>
                                    <li>Desktop non-motor</li>
                                    <li>Field non-motor</li>
                                    <li>Desktop motor</li>
                                    <li>CRM</li>
                                    <li>Once & done desktop assessors</li>
                                    <li>Legal officers (litigation managers, SLU & BLOs)</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Lead Through People Award" @click="nomCatSelected('Lead Through People')">
                            <b-card-text class="text-left">
                                <h3>Lead Through People Award</h3>
                                <hr />
                                <p>The award goes to the best team leader/team manager from JG09  to JG07, managing teams and has shown above average leadership.</p>
                                <ul>
                                    <li>Qualities displayed</li>
                                    <li>Leading by example</li>
                                    <li>Leading with empathy</li>
                                    <li>Exceptional employee engagement results (annual award)</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                    </template>

                    <!-- All of santam except - Santam: Commercial & Personal -->
                    <template v-else-if="memberClusterCode==='stm' && memberDivisionCode!=='stm_2196'">
                        <b-tab title="Excellence" @click="nomCatSelected('Excellence')">
                            <b-card-text class="text-left">
                                <h3>Excellence</h3>
                                <hr />
                                <p>What does Excellence look like?</p>
                                <ul>
                                    <li>You are willing to go beyond the required expectations and do whatever it takes to get the job done</li>
                                    <li>You provide all clients/ customers (both internal and external) with quality service; seek to deliver on their expectations</li>
                                    <li>You look to learn more all the time, in order to improve</li>
                                    <li>You display high levels of professionalism and quality</li>
                                    <li>You encourage others to do more and better</li>
                                    <li>You celebrate the successes of others as well as your own</li>
                                    <li>You take feedback on board and continually seek opportunities to improve</li>
                                    <li>You plan and execute; you don't 'talk' about doing, you actually do them</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Humanity" @click="nomCatSelected('Humanity')">
                            <b-card-text class="text-left">
                                <h3>Humanity</h3>
                                <hr />
                                <p>What does Humanity look like? </p>
                                <ul>
                                    <li>You display acceptance and tolerance for others</li>
                                    <li>You show empathy towards others' needs/ situations</li>
                                    <li>You seek to understand the concerns and interests of others</li>
                                    <li>You acknowledge how people feel in a situation, even when nothing can be done about those emotions</li>
                                    <li>You listen to the views of others in a way that shows that you are really interested and willing to hear their views</li>
                                    <li>You are respectful of other's cultural and socioeconomic differences</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Integrity" @click="nomCatSelected('Integrity')">
                            <b-card-text class="text-left">
                                <h3>Integrity</h3>
                                <hr />
                                <p>What does Integrity look like?</p>
                                <ul>
                                    <li>You are honest at all times; you do not change the narrative to win favour with the audience</li>
                                    <li>You are consistent in your treatment of others</li>
                                    <li>You demonstrate fairness in how you deal with a difficult situation</li>
                                    <li>You listen carefully to others' ideas, knowledge and experiences</li>
                                    <li>You are reliable and dependable at all times; you follow through</li>
                                    <li>You apply professional and ethical standards in your behaviour; you do the 'right' thing</li>
                                    <li>You speak out against unethical behaviour, even when it is hard to do so</li>
                                    <li>You take responsibility and deliver on promises made; manage expectations.</li>
                                    <li>You are willing to publicly admit to making a mistake and you don't 'pass the buck'</li>
                                    <li>You are open to and you encourage feedback</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Innovation" @click="nomCatSelected('Innovation')">
                            <b-card-text class="text-left">
                                <h3>Innovation</h3>
                                <hr />
                                <p>What does Innovation look like?</p>
                                <ul>
                                    <li>You display an openness to new ideas and experiences (talk about doing things differently)</li>
                                    <li>You take initiative (are proactive- you anticipate or spot opportunities for change)</li>
                                    <li>You display the courage to challenge status quo / old ideas and suggest new ways of working / You are interested in finding new and improved methods of doing things</li>
                                    <li>You propose new ideas in relation to your own work / you share new ideas/ suggestions for areas that can be improved</li>
                                    <li>You display a willingness to try unfamiliar tasks</li>
                                    <li>You contribute new ideas in your team</li>
                                    <li>You allow others the freedom to exercise creativity, new ideas, introduce new systems etc...</li>
                                    <li>You support collaboration across work / business units that may normally not work together</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Passion" @click="nomCatSelected('Passion')">
                            <b-card-text class="text-left">
                                <h3>Passion</h3>
                                <hr />
                                <p>What does Passion look like?</p>
                                <ul>
                                    <li>You are ambitious to do more and better and you seek to excel</li>
                                    <li>You are proud of Santam and the role you play</li>
                                    <li>You openly endorse and promote the company 'product'</li>
                                    <li>You display high levels of motivation and are fired up for the challenge</li>
                                    <li>You demonstrate high levels of engagement, energy and enthusiasm,</li>
                                    <li>You demonstrate a positive attitude towards getting things done, even when the challenge is great</li>
                                    <li>You take full ownership and commit fully to delivering what is required</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                    </template>


                    <!-- ************ SEM ************-->
                    <template v-else-if="memberClusterCode==='sem'">
                        <b-tab title="Excellence In Collaboration Award" @click="nomCatSelected('Excellence In Collaboration Award')">
                            <b-card-text class="text-left">
                                <h3>The excellence in collaboration award seeks to do the following:</h3>
                                <hr />
                                <p>This award recognises creative collaboration within the Sanlam Emerging Markets cluster for the purpose of addressing a need to enable individuals to work together especially in a joint intellectual effort; to build collaborative relationships with colleagues and clients and the ability to work with diverse teams to achieve defined and common business goals.</p>
                                <div>The excellence in collaboration award seeks to do the following:</div>
                                <ul>
                                    <li>Foster a culture of collaboration in SEM</li>
                                    <li>Prompt and increase problem solving</li>
                                    <li>Boost morale across the cluster</li>
                                    <li>Lead to higher retention rates</li>
                                    <li>Encourage innovation</li>
                                    <li>Dissuade the fear of failure</li>
                                    <li>Foster cohesion between team members</li>
                                    <li>Leverage team-member strengths</li>
                                    <li>Help SEM stay competitive</li>
                                    <li>Lead to increased Operating Profits</li>
                                </ul>
                            </b-card-text>
                        </b-tab>
                    </template>


                    <!-- ************ ALL OTHERS ************-->
                    <template v-else>
                        <b-tab title="Unknown Cluster">
                            <b-card-text class="text-left">
                                <h3>Unknown Cluster Info: {{memberClusterCode}}</h3>
                            </b-card-text>
                        </b-tab>
                    </template>

                </b-tabs>
            </section>

            <section class="card card-default mt-3" v-if="form.nomCat !== null">
                <div class="card-header">
                    <h5>Nomination for: <b class="darkShade">{{ form.nomCat }}</b></h5>
                </div>

                <div class="card-body">

                    <template v-if="true && form.nomCat === 'SRA CEO Award'">
                        <!-- SRA CEO Award nominations closed -->
                        <div class="alert alert-info">SRA CEO Award nominations closed on 29 February 2024</div>
                    </template>
                    <template v-else>
                        <div><strong>Nominees:</strong></div>
                        <div v-for="r in selectedNominees" :key="r._rowNum" class="row">
                            <template v-if="r.isEntireDivision">
                                <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                                <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                            </template>
                            <template v-else>
                                <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                                <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                                <div class="col">{{ r.division }}</div>
                            </template>
                        </div>

                        <div v-if="selectedNominees.length===0" class="text-danger">Choose who to nominate.</div>
                        <div class="mt-1">
                            <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm" class="mt-2">{{selectedNominees.length===0 ? 'Select' : 'Change'}}  Nominees</b-button>
                        </div>

                        <hr />

                        <b-form @submit.prevent="onSubmitNom" novalidate>

                            <!--<b-form-group id="groupAwardCategory" label-for="selectNomCat" label="Award Category:" :invalid-feedback="formErrors.nomCat" description="">
                <b-form-select v-model="form.nomCat" :options="nomCatList" value-field="nomCatValue" text-field="nomCat" :state="formState.nomCat" />
            </b-form-group>-->
                            <!--<b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="formErrors.EmailAddress" description="Your email address">
                <b-form-input id="txtEmail" v-model="form.EmailAddress" type="text" :state="formState.EmailAddress" placeholder="Enter your email address" />
            </b-form-group>-->

                            <b-form-group id="groupMotivation" label-for="txtMessage" label="Motivation:" :invalid-feedback="formErrors.motivation" :description="1500-form.motivation.length + ' characters remaining'">
                                <b-form-textarea id="txtMotivation" v-model="form.motivation" :state="formState.motivation" placeholder="Provide a short motivation" rows="3" max-rows="5" maxlength="1500" />
                            </b-form-group>

                            <b-form-group id="groupAction" label-for="txtAction" label="Actions Taken:" :invalid-feedback="formErrors.action" :description="1500-form.action.length + ' characters remaining'">
                                <b-form-textarea id="txtAction" v-model="form.action" :state="formState.action" placeholder="Describe Actions Taken" rows="3" max-rows="5" maxlength="1500" />
                            </b-form-group>

                            <b-form-group id="groupImpact" label-for="txtImpact" label="Impact:" :invalid-feedback="formErrors.impact" :description="1500-form.impact.length + ' characters remaining'">
                                <b-form-textarea id="txtImpact" v-model="form.impact" :state="formState.impact" placeholder="Impact thereof" rows="3" max-rows="5" maxlength="1500" />
                            </b-form-group>

                            <div class="text-right"><b-button type="submit" variant="primary">Submit Nomination</b-button></div>
                        </b-form>
                    </template>
                </div>
            </section>
        </template>
        <!--
        **************************************************************************************************************************************************
                                                                    M O D A L S
        **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalSelectCluster" title="Select a Cluster" hide-footer size="md" no-close-on-backdrop>
            <b-form-group id="groupSelectCluster" label-for="selectCluster" label="Cluster:" description="">
                <b-form-radio-group v-model="tmpSelectedClusterCode" stacked>
                    <b-form-radio value="sra">SA Retail Affluent</b-form-radio>
                    <b-form-radio value="srm">SA Retail Mass</b-form-radio>
                    <b-form-radio value="sc">Sanlam Corporate</b-form-radio>
                    <hr />
                    <b-form-radio value="lsm">Sanlam Life & Savings</b-form-radio>
                </b-form-radio-group>
                <b-alert show variant="info" class="mt-3" v-if="selectedNominees.length!==0">Note: Changing the cluster will remove the {{selectedNominees.length}} selected nominees</b-alert>
            </b-form-group>
            <hr />
            <div class="text-right">
                <b-button type="button" variant="primary" @click="clusterChanged">Ok</b-button>
            </div>
        </b-modal>

        <b-modal id="modalChooseNominees" title="Award Nomination" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Choose who to nominate"
                           selectedTitle="Selected nominees"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           isCountryFixed
                           :selectedMems="selectedNominees"
                           :showContinue="false"
                           excludeDivisionCode="sra_2104,sra_4181"
                           class="mb-3" />

            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>
        <!--
        <div class="text-right"><a href="#" v-on:click.prevent="formReset">Reset</a></div>
        <member-select title="Choose who to nominate"
                       selectedTitle="Selected nominees"
                       :defaultCountryCode="memberClusterCode"
                       :defaultDivisionCode="memberDivisionCode"
                       isCountryFixed
                       v-bind:selectedMems="selectedNominees"
                       v-on:continue="nomineesSelected"
                       v-on:cancel="nomineesSelectedCancel" />
    -->
    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
    import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'AwardNomination',
        components: { MemberSelect, SitecodeImage },
        data() {
            return {
                displayPart: '', //'' don't show anythjing
                santamCEOAwardStatus: 'closed', //nominating, voting, closed
                formInit: {},
                form: { nomCat: null, motivation: '', action: '', impact: '' },
                formErrors: {},
                formState: {},



                selectedNominees: [],
                tabIndex: 0,
                tabSelected: false,
                selectedClusterCode: null, //used for life&savings to choose a differnet cluster
                tmpSelectedClusterCode: null,
				hallOfFameData: [],
            }
        },
        computed: {
			isPPE() {
                return this.$store.state.memberStore.isPPE;
			},
            isLoggedIn() {
				return this.$store.state.isLoggedIn && this.memberDivisionCode != 'sra_2104' //Disable SanPay division from access
            },
            memberClusterCode() {
                return this.selectedClusterCode === null ? this.$store.state.memberStore.member.clusterCode : this.selectedClusterCode;
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isHumanCapital() { //Group Human Capital Award
                return this.$store.state.memberStore.member.isHumanCapital;
            },
            isSantamHumanCapital() { //Santam Human Capital Award
                return this.memberClusterCode === "stm";
            },
            isSantamHumanCapital() { //Santam Human Capital Award
                return this.memberClusterCode === "stm";
            },
            isSBSAward() {
                //Access for "stm_3930-Santam: Broker Solutions"" and "stm_4185-Santam: Broker Solutions: Namibia"
                var isSBSAwardRunning = true; //Set if award is currently running now

                return isSBSAwardRunning ? this.memberDivisionCode == "stm_3930" || this.memberDivisionCode == "stm_4185" : false; //SBS Award IS RUNNING.
            },
            isSSSAward() { 
                ////Access for "Santam: Specialist Solutions"" - Only stm_3869: Santam: Specialist Solutions
                var isSSSAwardRunning = true; //Set if award is currently running now
 
                return isSSSAwardRunning && this.$store.state.isLoggedIn && this.memberDivisionCode == 'stm_3869';
            },
            nomCatList() {
                const nomcats = [
                    { nomCatValue: 'sra', nomCat: 'Client Champion Award' },
                    { nomCatValue: 'sra', nomCat: 'Influencer Award' },
                    { nomCatValue: 'sra', nomCat: 'Innovator Award' },
                    { nomCatValue: 'sra', nomCat: 'SRA CEO Award' },
                    { nomCatValue: 'srm', nomCat: 'Care for all' },
                    { nomCatValue: 'srm', nomCat: 'Collaborating' },
                    { nomCatValue: 'srm', nomCat: 'Leading through Innovation' },
                    { nomCatValue: 'srm', nomCat: 'Integrity in everything you do' },
                   // { nomCatValue: 'srm', nomCat: 'RM CEO Award' },
                    { nomCatValue: 'lsm', nomCat: 'Superior Performance Award' },
                    { nomCatValue: 'lsm', nomCat: 'Leadership Award' },
                    { nomCatValue: 'lsm', nomCat: 'Innovation Award' },
                    { nomCatValue: 'sky', nomCat: 'Care for all' },
                    { nomCatValue: 'sky', nomCat: 'Collaborating' },
                    { nomCatValue: 'sky', nomCat: 'Leading through Innovation' },
                    { nomCatValue: 'sky', nomCat: 'Integrity in everything you do' },
                    //{ nomCatValue: 'sky', nomCat: 'RM CEO Award' },
                    { nomCatValue: 'sem', nomCat: 'Excellence In Collaboration Award' },
                    //{ nomCatValue: 'go', nomCat: 'Client Centricity' }, //DELETE ME !!!
                    { nomCatValue: 'go', nomCat: 'Superior Performance Award' },
                    { nomCatValue: 'go', nomCat: 'Leadership Award' },
                    { nomCatValue: 'go', nomCat: 'Innovation Award' },
                    { nomCatValue: 'sf', nomCat: 'Superior Performance Award' },
                    { nomCatValue: 'sf', nomCat: 'Leadership Award' },
                    { nomCatValue: 'sf', nomCat: 'Innovation Award' },
     //               { nomCatValue: 'sig', nomCat: 'Collaboration' },
     //               { nomCatValue: 'sig', nomCat: 'Creativity & Innovation' },
					//{ nomCatValue: 'sig', nomCat: 'Client Centricity'},
					//{ nomCatValue: 'sig', nomCat: 'Distinguished Leadership' },
     //               { nomCatValue: 'sig', nomCat: 'Annual SI Group Award' },
                    { nomCatValue: 'sig', nomCat: 'Care' },
                    { nomCatValue: 'sig', nomCat: 'Innovation' },
                    { nomCatValue: 'sig', nomCat: 'Integrity' },
                    { nomCatValue: 'sig', nomCat: 'Collaboration' },
                    { nomCatValue: 'sig', nomCat: 'Human Centred Leadership' },
                    { nomCatValue: 'sc', nomCat: 'Pillar of Trust' },
                    { nomCatValue: 'sc', nomCat: 'Heartfelt Hero' },
                    { nomCatValue: 'sc', nomCat: 'Synergy Superstar' },
                    { nomCatValue: 'sc', nomCat: 'Trailblazer of tomorrow' },
                    { nomCatValue: 'sc', nomCat: 'Performance Excellence' },
                    { nomCatValue: 'sc', nomCat: 'Client Champion' },
                    { nomCatValue: 'sc', nomCat: 'Community Catalyst' },
                    { nomCatValue: 'stm', nomCat: 'Excellence' },
                    { nomCatValue: 'stm', nomCat: 'Humanity' },
                    { nomCatValue: 'stm', nomCat: 'Integrity' },
                    { nomCatValue: 'stm', nomCat: 'Innovation' },
					{ nomCatValue: 'stm', nomCat: 'Passion' },
					{ nomCatValue: 'stm', nomCat: 'Lead With Pride' },
					{ nomCatValue: 'stm', nomCat: 'Hero Award' },
					{ nomCatValue: 'stm', nomCat: 'Differentiation Award' },
					{ nomCatValue: 'stm', nomCat: 'Lead Through People' },
					{ nomCatValue: 'stm', nomCat: 'Peace of Mind' },
                    { nomCatValue: 'saf', nomCat: 'Care for all' },
                    { nomCatValue: 'saf', nomCat: 'Collaborating' },
                    { nomCatValue: 'saf', nomCat: 'Leading through Innovation' },
                    { nomCatValue: 'saf', nomCat: 'Integrity in everything you do' },
                    //{ nomCatValue: 'saf', nomCat: 'RM CEO Award' }
                ];

                return nomcats.filter(i => i.nomCatValue === this.memberClusterCode);
            }
        },
        watch: {
            isLoggedIn(isLoggedIn) {
                if (isLoggedIn) {
                    this.onLoad();
                }
            },
        },
        created() {
            this.formInit = { ...this.form }; //Create a copy of the form init values, so we can use this to reset the form

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.onLoad();
                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                })
        },

        mounted() {
            this.formStateUpdate(true);
            this.hallOfFame();
        },
        methods: {
            onLoad() {
                this.displayPart = this.isSantamHumanCapital || this.isHumanCapital || this.memberClusterCode == 'stm' || this.isSBSAward || this.isSSSAward ? 'choose_award' : 'nom_info'; //Show preview page if HCA or SBO
            },

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            formReset() {
                this.form = { ...this.formInit };
                this.displayPart = 'new_nom';
                this.$nextTick(() => {
                    this.nomCatInit();
                });
                this.selectedNominees = [];
            },

            formStateUpdate(init) {
                var fields = Object.getOwnPropertyNames(this.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (init) {
                        this.formState[field] = null;
                        this.formErrors = {}; //reset formErrors object
                    } else if (typeof this.formErrors[field] === 'undefined' || this.formErrors[field] === '') {
                        this.formState[field] = true;
                        console.log("field: " + field + " " + JSON.stringify(this.formState[field]));
                    } else {
                        this.formState[field] = false;
                        console.log("field: " + field + " false");
                    }
                }
            },
            showChangeCluster() {
                this.tmpSelectedClusterCode = this.memberClusterCode;
				this.$bvModal.show('modalSelectCluster');
			},
            clusterChanged() {
                this.$bvModal.hide('modalSelectCluster');
				if (this.selectedClusterCode == this.tmpSelectedClusterCode) return; //no change
				this.selectedClusterCode = this.tmpSelectedClusterCode;
				this.selectedNominees = [];
				this.nomCatInit();
            },
            nomCatInit() {
                if (!this.memberClusterCode) return;
                this.tabIndex = 0;
                this.tabSelected = false;
                switch (this.memberClusterCode) {
                    case 'sra':
                        this.nomCatSelected('SRA CEO Award');
                        break;
                    case 'srm':
                    case 'saf':
                    case 'sky':
                        this.nomCatSelected('Care for all'); //srm,saf,sky
                        break;
                    case 'sig':
                        this.nomCatSelected('Collaboration');
                        break;
                    case 'go':
                    case 'lsm':
                    case 'sf':
                        this.nomCatSelected('Superior Performance Award'); //go & lsm & sf
                        break;
                    case 'sc':
                        this.nomCatSelected('Pillar of Trust');
                        break;
                    case 'stm':
						this.nomCatSelected(this.memberDivisionCode === 'stm_2196' ? 'Lead With Pride' : 'Excellence'); //stm_2196=stm_Santam: Claims Department
                        break;
                    case 'sem':
                        this.nomCatSelected('Excellence In Collaboration Award');
                        break;

                    default:
                        this.msgBox('Cannot find Cluster Code: [' + this.memberClusterCode + '] in nomCatInit()');
                }
            },
            nomCatSelected(nomCat) {
                let nomCats = this.nomCatList.map(e => { return e.nomCat; });

                if (!nomCats.includes(nomCat)) {
                    this.msgBox('Cannot find "' + nomCat + '" in [' + nomCats.join(', ') + ']!');
                    return;
                }
                this.form.nomCat = nomCat;
                this.tabSelected = true;
            },

            nomineesSelected(e) {
                this.selectedNominees = e;
                this.displayPart = 'new_nom';
            },
            nomineesSelectedCancel() {
                this.displayPart = 'new_nom';
            },
            onSubmitNom() {
                this.form.nominees = this.selectedNominees.map(nominee => nominee.isEntireDivision ? 'div:' + nominee.entireDivisionCode : nominee.memberId)

                this.formStateUpdate(true);
                return axios
                    .post(
                        '/api/nom/newnom',
                        this.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.formReset();
                        this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.formErrors = error.response.data.formErrors;
                            this.formStateUpdate(false);
                        }
                    })
                    ;
            },
            hallOfFame() {
				axios.post('/api/nom/halloffame', { })
					.then(({ data }) => {
						this.hallOfFameData = new sjData(data.report).data;
					})
					.catch((error) => {
						if (error) {
							this.msgBox('An unexpected error occured');
							//this.msgBox(error.response.data.message);
						}
					})
			}
        }


    }
</script>
